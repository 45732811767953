import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMainContext } from '../../context/MainContext';

const IconBox = () => {
    const { profile } = useMainContext();
    const [iconbox, setIconbox] = useState([]);
    useEffect(() => {
        if(profile){
            setIconbox([
                {
                    id: 1,
                    heading: profile.primary_phone,
                    subheading: "Support 24/7 - Online 24 hours",
                    morelink: "Call us now",
                    icon: 'icon-phone'
                },
                {
                    id: 2,
                    heading: profile.address,
                    subheading: profile.office_time,
                    morelink: "Saturday: Close",
                    icon: 'icon-map'
                },
                {
                    id: 3,
                    heading: profile.primary_mail,
                    subheading: "Support 24/7 - Online 24 hours",
                    morelink: "Mail us now",
                    icon: 'icon-envelope'
                }
            ])
        }
    },[profile]);


    return (
        <div className="row gutter-16">
            {iconbox.map(data => (
                <div className="col-md-4" key={data.id}>
                    <div className="spacer clearfix" />
                    <div className="alphaconcern-icon-box icon-top align-center accent-color style-3 bg-light-snow clearfix">
                        <div className="icon-wrap">
                            <i className={data.icon} />
                        </div>
                        <div className="text-wrap">
                            <h5 className="heading"><Link to="#">{data.heading}</Link></h5>
                            <p className="sub-heading">{data.subheading}</p>
                            <span className="more-link"><Link to="#">{data.morelink}</Link></span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default IconBox;
