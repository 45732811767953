import Home from './Home'
import About from './About'
import Services from './Services'
import ServiceDetail from './ServiceDetail'
import Projects from './Projects'
import ProjectDetail from './ProjectDetail'
import Blog from './Blog'
import BlogSingle from './BlogSingle'
import Contact from './Contact'

const routes = [
    { path: '/', component: Home},
    { path: '/about-us', component: About},
    { path: '/services', component: Services},
    { path: '/services-detail/:slug', component: ServiceDetail},
    { path: '/project', component: Projects},
    { path: '/project-detail/:slug', component: ProjectDetail},
    { path: '/blog', component: Blog},
    { path: '/blog-single', component: BlogSingle},
    { path: '/contact', component: Contact},
]

export default routes;