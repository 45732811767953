import React from "react";
import parser from "react-html-parser";
import Slider from "react-slick";
import Sidebar from "./Sidebar";

const settings = {
  dots: true,
  infinite: false,
  autoplay: true,
  autoplaySpeed: 4000,
  speed: 1000,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const ContentProject = ({ data }) => {
  return (
    <div className="detail-gallery">
      {data?.images?.length > 0 && (
        <Slider {...settings}>
          {data?.images?.map((ser, ind) => (
            <div className="gallery-item" key={ind}>
              <div className="inner">
                <div className="thumb">
                  <img
                    src={ser.file_path_url}
                    alt="imagealt"
                    className="gallery-image"
                  />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}
      <div className="spacer clearfix" />
      <div className="flat-content-wrap style-3 clearfix">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Sidebar data={data} />
            <div className="spacer clearfix" />
          </div>
          <div className="col-lg-8 col-md-4 col-sm-12">
            <div className="alphaconcern-headings style-2 clearfix">
              <h2 className="heading line-height-62">PROJECT DESCRIPTION</h2>
              <div className="sep has-width w80 accent-bg clearfix"></div>
            </div>
            {data?.description && parser(data?.description)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentProject;
