import React from 'react';
import { Link ,useLocation} from 'react-router-dom';

const BottomBar = () => {
  const { pathname } = useLocation();

    const menus = [
        {
            id: 1,
            name: 'Home',
            links: '/'
        },
        {
            id: 2,
            name: 'About Us',
            links: '/about-us'
        },
        {
            id: 3,
            name: 'Services',
            links: '/services'
        },
        {
            id: 4,
            name: 'Projects',
            links: '/project'
        },
        {
            id: 7,
            name: 'Contact',
            links: '/contact'
        }
    ];

    return (
        <div id="bottom" className="clearfix has-spacer"  style={{ background:"#00897b", color:"#FFF"}}>
            <div id="bottom-bar-inner" className="container">
                <div className="bottom-bar-inner-wrap">
                    <div className="bottom-bar-content">
                        {/* <div id="copyright">© <span className="text">Construction Template. Design <Link to="#"
                                    className="text-accent">by @Niche_Theme</Link> </span>
                        </div> */}
                    </div>{/* /.bottom-bar-content */}
                    <div className="bottom-bar-menu">
                        <ul className="bottom-nav">
                            {menus.map(data => (
                                <li
                                    className={pathname === data.links ? "menu-item current-menu-item" : "menu-item"}
                                    key={data.id}
                                >
                                    <Link
                                        to={data.links}
                                    >
                                        {data.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BottomBar;
