import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useGetAbout } from "../query-hook/home";

const initialState = {
    tags:[
        "architecture",
        "construction",
        "building",
        "villa"
    ],
    galleries:[],
    profile: {
        "title": "Alpha Concern Pvt. Ltd.",
        "introduction": "We have successfully completed projects in numerous districts across Nepal. Alpha Concern has a proven track record of:\r\n\r\n1.Completing projects on time & Following budget guidelines\r\n2.Elevated quality of workmanship\r\n3.Meeting diverse supplier requirements\r\n4.Implementing appropriate safety precautions and procedures",
        "address": "Maharajgunj, Kathmandu-04",
        "primary_phone": "9851108892",
        "primary_mail": "alpha.concern@gmail.com",
        "office_time": "10:00 AM - 6:00 PM",
        "file_path_url": null,
        "other_mail": [
            {
                "icon": null,
                "label": "Support",
                "value": "alpha.concern@gmail.com"
            }
        ],
        "other_phone": [
            {
                "icon": null,
                "label": "Support",
                "value": "9851108892"
            }
        ],
        "social_link": [
            {
                "icon": "fa fa-facebook",
                "label": "Facebook",
                "value": "www.facebook.com"
            },
            {
                "icon": "fa fa-twitter",
                "label": "Twitter",
                "value": "www.twitter.com"
            },
            {
                "icon": "fa fa-instagram",
                "label": "Instagram",
                "value": "www.instagram.com"
            }
        ],
        "meta_title": "Alpha Concern Pvt. Ltd.",
        "meta_description": "Alpha Concern Pvt. Ltd.",
        "copyright_text": null,
        "footer_text": "We have over 5 years of experience able to help you 24 hours a day."
    }
};

export const MainProvider = ({ children }) => {
    const { data: aboutData } = useGetAbout();
    const [mainState, setMainState] = useState(initialState);

    useEffect(() => {
        if (aboutData?.result) {
            setMainState({ ...mainState, 
                profile: aboutData?.result?.profile,
                galleries: aboutData?.result?.galleries,
                tags: aboutData?.result?.tags,
            });
        }
    }, [aboutData]);

    const memoedValue = useMemo(
        () => ({
            ...mainState,
        }),
        [mainState]
    );

    return (
        <MainContext.Provider value={memoedValue}>{children}</MainContext.Provider>
    );
};

export const MainContext = createContext(initialState);

export const useMainContext = () => {
    const context = useContext(MainContext);
    if (context === undefined) {
        throw new Error("useMainContext must be used within a MainContextProvider");
    }
    return context;
};
