import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BannerTitle from "../components/about/banner/BannerTitle";
import { Header, Footer, TopBar, BottomBar } from "../components/general/index";
import { TabProject } from "../components/home";
import { useGetProject } from "../query-hook/home";
import { groupBy } from "lodash";
import SEO from "../components/Seo";
import { Loader } from "../components/Loader";

const Projects = () => {
  const { data: projectData, isLoading } = useGetProject();
  const [activeTab, setActiveTab] = useState("all");
  const [projectCategory, setProjectCategory] = useState();
  const [projects, setProjects] = useState();

  useEffect(() => {
    if (projectData?.result?.length > 0) {
      const proj = groupBy(projectData?.result, "category_code");
      setProjectCategory({ ...{ all: projectData?.result }, ...proj });
      setProjects(projectData?.result);
    }
  }, [projectData]);

  const headers = [
    {
      id: 1,
      logoweb: "assets/img/logo-small.png",
      names: "Projects",
    },
  ];

  const banners = [
    {
      id: 1,
      links: "",
      titlelink: "",
      border: "",
      name: "Projects",
      heading: "Projects Grid",
    },
  ];

  return (
    <div className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
      {isLoading && <Loader />}
      <SEO
        title="Our Projects: Showcase of Excellence in Construction"
        description="Discover Alpha Concern's portfolio of projects, highlighting our expertise and commitment to quality in construction. Explore our completed endeavors and see how we're shaping the landscape of tomorrow."
        name="Alpha Concern Pvt. Ltd."
        type="website"
      />
      <div id="wrapper" className="animsition">
        <div id="page" className="clearfix">
          <div id="site-header-wrap">
            <TopBar />
            {headers.map((data, idx) => (
              <Header data={data} key={idx} />
            ))}
          </div>
          {banners.map((data) => (
            <BannerTitle key={data.id} data={data} />
          ))}
          <div id="main-content" className="site-main clearfix">
            <div id="content-wrap">
              <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                  <div className="page-content">
                    <div className="row-services">
                      <div className="container">
                        <div className="spacer clearfix" />
                        <ul className="alphaconcern-filter style-1 clearfix">
                          {projectCategory &&
                            Object.keys(projectCategory)?.length > 0 &&
                            Object.keys(projectCategory)?.map((cat, cind) => (
                              <li
                                className={activeTab === cat ? "active" : ""}
                                key={cind}
                                onClick={() => {
                                  setActiveTab(cat);
                                  setProjects(projectCategory[cat]);
                                }}
                              >
                                <Link
                                  to="#"
                                  data-filter="*"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {cat}
                                </Link>
                              </li>
                            ))}
                        </ul>
                        <div className="spacer clearfix" />
                        <TabProject projectData={projects} />
                        <div className="spacer clearfix" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
            {headers.map((data, idx) => (
              <BottomBar data={data} key={idx} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
