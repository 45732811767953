import React from "react";
import BannerTitle from "../components/about/banner/BannerTitle";
import { Header, Footer, TopBar, BottomBar } from "../components/general/index";
import { ServiceSlide } from "../components/services/index";
import { useGetService } from "../query-hook/home";
import SEO from "../components/Seo";
import { Loader } from "../components/Loader";

const Services = () => {
  const { data: serviceData,isLoading } = useGetService();
  const headers = [
    {
      id: 1,
      logoweb: "assets/img/logo-small.png",
      names: "Services",
    },
  ];

  const banners = [
    {
      id: 1,
      links: "",
      titlelink: "",
      border: "",
      name: "Services",
      heading: "All Services",
    },
  ];

  return (
    <div className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
      {isLoading && <Loader />}
      <SEO
        title="Our Services: Comprehensive Construction Solutions"
        description="Explore Alpha Concern's diverse range of construction services tailored to meet your needs. From project planning to execution, our team is committed to delivering excellence every step of the way."
        name="Alpha Concern Pvt. Ltd."
        type="website"
      />
      <div id="wrapper" className="animsition">
        <div id="page" className="clearfix">
          <div id="site-header-wrap">
            <TopBar />
            {headers.map((data, idx) => (
              <Header data={data} key={idx} />
            ))}
          </div>
          {banners.map((data) => (
            <BannerTitle key={data.id} data={data} />
          ))}
          <div id="main-content" className="site-main clearfix">
            <div id="content-wrap">
              <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                  <div className="page-content">
                    <ServiceSlide serviceData={serviceData?.result} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          {headers.map((data, idx) => (
            <BottomBar data={data} key={idx} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
