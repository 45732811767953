import React from 'react';
import { Link } from 'react-router-dom';

const IconBox = () => {
    const iconbox = [
        {
            id: 1,
            icon: "autora-icon-quality",
            title: "BEST QUALITY",
            text: "Our safety culture will not be compromised in our pursuit of the highest quality standards            .."
        },
        {
            id: 2,
            icon: "autora-icon-time",
            title: "ON TIME",
            text: "We prioritize timely delivery, ensuring your project is completed efficiently and meets all deadlines."
        },
        {
            id: 3,
            icon: "autora-icon-author",
            title: "EXPERIENCED",
            text: "Our knowledgeable team provides years of industry experience to every facet of your project"
        }
    ];

    return (
        <div className="row">
            {iconbox.map(data => (
                <div className="col-md-4" key={data.id}>
                    <div className="spacer clearfix" />
                    <div className="alphaconcern-content-box clearfix" data-margin="0 5px 0 5px" data-mobilemargin="0 0 0 0">
                        <div className="alphaconcern-icon-box icon-top align-center has-width w95 circle light-bg accent-color style-1 clearfix">
                            <div className="icon-wrap">
                                <i className={data.icon} />
                            </div>
                            <div className="text-wrap">
                                <h5 className="heading"><Link to="#">{data.title}</Link></h5>
                                <div className="sep clearfix" />
                                <p className="sub-heading">{data.text}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default IconBox;
