import React from "react";
import { Link } from "react-router-dom";
import {
  Slider,
  TitleHeading,
  IconBox,
  About,
  Service,
  Project,
  ServicesBox,
  Testimonial,
  Overview,
  Faq,
  Partner,
} from "../components/home/index";
import { Header, Footer, TopBar, BottomBar } from "../components/general/index";

import { useGetClient, useGetHome } from "../query-hook/home";
import { useMainContext } from "../context/MainContext";
import SEO from "../components/Seo";
import { Loader } from "../components/Loader";

const Home = () => {
  const { data: homeData, isLoading } = useGetHome();
  const { data: clientData } = useGetClient();
  const { profile } = useMainContext();

  const headers = [
    {
      id: 1,
      logoweb: "assets/img/logo-small.png",
      names: "Home",
    },
  ];

  const headingchoose = [
    {
      id: 1,
      classnames: "heading",
      title: "YOUR BEST CHOOSE",
      text: "Over a dozen projects have been finished at Alpha Concern. Our combined experience gives us the expertise to help you.",
      classtext: "sub-heading",
    },
  ];

  const headingservice = [
    {
      id: 1,
      classnames: "heading",
      title: "TOP SERVICES",
      text: "Construction Services offers a full range of construction services from initial design to project completion.",
      classtext: "sub-heading",
    },
  ];

  const headingproject = [
    {
      id: 1,
      classnames: "heading text-white",
      title: "FEATURED PROJECT",
      classtext: "sub-heading",
    },
  ];

  const servicebox = [
    {
      id: 1,
      classnames: "heading font-size-30",
      title: "ALL SERVICES",
      classtext: "sub-heading font-weight-400",
      text: "Are you interested in finding out how we can make your project a success? Please contact us.",
    },
  ];

  return (
    <div className="header-fixed page no-sidebar header-style-2 topbar-style-2 menu-has-search">
      {isLoading && <Loader />}
      <SEO
        title={profile?.meta_title || "Welcome : Building Tomorrow, Today"}
        description={
          profile?.meta_description ||
          "Explore Alpha Concern's home page and discover our commitment to excellence in construction. From groundbreaking projects to innovative solutions, we're shaping the future of construction with quality craftsmanship and dedication to our clients' needs."
        }
        name="Alpha Concern Pvt. Ltd."
        type="website"
      />
      <div id="wrapper" className="animsition">
        <div id="page" className="clearfix">
          <div id="site-header-wrap">
            <TopBar />
            {headers.map((data, idx) => (
              <Header data={data} key={idx} />
            ))}
          </div>
          <div id="main-content" className="site-main clearfix">
            <div id="content-wrap">
              <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                  <div className="page-content">
                    {homeData?.result?.banners?.length > 0 && (
                      <div className="rev_slider_wrapper fullwidthbanner-container">
                        <div
                          id="rev-slider1"
                          className="rev_slider fullwidthabanner"
                        >
                          <Slider bannerData={homeData?.result?.banners} />
                        </div>
                      </div>
                    )}
                    <div className="row-iconbox">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="spacer clearfix" />
                            {headingchoose.map((data) => (
                              <TitleHeading data={data} key={data.id} />
                            ))}
                          </div>
                        </div>

                        <IconBox />
                        <div className="row">
                          <div className="col-md-12">
                            <div className="spacer clearfix" />
                            <div className="elm-button text-center">
                              <Link
                                to="/about-us"
                                className="alphaconcern-button bg-accent"
                              >
                                ABOUT US
                              </Link>
                            </div>
                            <div className="spacer clearfix" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <About />
                    <div className="row-services">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="spacer clearfix" />
                            {headingservice.map((data) => (
                              <TitleHeading data={data} key={data.id} />
                            ))}
                            <div className="spacer clearfix" />
                            <Service serviceData={homeData?.result?.services} />
                            <div className="spacer clearfix" />
                            <div className="elm-button text-center">
                              <Link
                                to="/services"
                                className="alphaconcern-button bg-accent"
                              >
                                ALL SERVICES
                              </Link>
                            </div>
                            <div className="spacer clearfix" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row-project parallax parallax-1 parallax-overlay">
                      <div className="project-overlay">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="spacer clearfix" />
                              {headingproject.map((data) => (
                                <TitleHeading data={data} key={data.id} />
                              ))}
                              <div className="spacer clearfix" />
                              <Project
                                projectData={homeData?.result?.projects}
                              />
                              <div className="spacer clearfix" />
                              <div className="elm-button text-center">
                                <Link
                                  to="#"
                                  className="alphaconcern-button bg-accent"
                                >
                                  ALL PROJECTS{" "}
                                </Link>
                              </div>
                              <div className="spacer clearfix" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row-iconbox">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="spacer clearfix" />
                            {servicebox.map((data) => (
                              <TitleHeading data={data} key={data.id} />
                            ))}
                            <div className="spacer clearfix" />
                            <ServicesBox
                              serviceCategoryData={
                                homeData?.result?.serviceCategory
                              }
                            />
                            <div className="spacer clearfix" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <Testimonial
                      testimonialData={homeData?.result?.testimonials}
                    />
                    {/* <div className="row-quote bg-row-1">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="spacer clearfix"
                            />
                            <div className="alphaconcern-quote style-1 clearfix">
                              <div className="quote-item">
                                <div className="inner">
                                  <div className="heading-wrap">
                                    <h3 className="heading">
                                      START EXECUTING YOUR PROJECT
                                    </h3>
                                  </div>
                                  <div className="button-wrap has-icon icon-left">
                                    <Link
                                      to="#"
                                      className="alphaconcern-button bg-white small"
                                    >
                                      <span>
                                        (+613) 8376 6284{" "}
                                        <span className="icon">
                                          <i className="autora-icon-phone-contact" />
                                        </span>
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="spacer clearfix"
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="row-tabs">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="spacer clearfix"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <Overview />
                          <Faq />
                        </div>
                      </div>
                    </div> */}
                    <Partner clientData={clientData?.result} />
                  </div>
                </div>
              </div>
            </div>
            <Footer />
            {headers.map((data, idx) => (
              <BottomBar data={data} key={idx} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
