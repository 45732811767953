const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
        namesub: []
    },
    {
        id: 2,
        name: 'About Us',
        links: '/about-us',
        namesub: [],
    }, {
        id: 3,
        name: 'Services',
        links: '/services',
        namesub: [],

    },
    {
        id: 4,
        name: 'Projects',
        links: '/project',
        namesub: [],

    },
    // {
    //     id: 5,
    //     name: 'Page',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Page Testimonial',
    //             links: '/page-testimonial'
    //         },
    //         {
    //             id: 2,
    //             sub: 'Page Pricing',
    //             links: '/page-pricing'
    //         }
    //     ],
    // },
    // {
    //     id: 6,
    //     name: 'Blog',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Blog',
    //             links: '/blog'
    //         },
    //         {
    //             id: 2,
    //             sub: 'Blog Single',
    //             links: '/blog-single'
    //         }
    //     ],
    // },
    {
        id: 7,
        name: 'Contact',
        links: '/contact',
        namesub: [],
    }
]

export default menus;