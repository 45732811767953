import React from "react";
import { Link } from "react-router-dom";
import { useMainContext } from "../../context/MainContext";

const Footer = () => {
  const { profile, tags, galleries } = useMainContext();
  return (
    <footer id="footer" className="clearfix">
      <div id="footer-widgets" className="container">
        <div className="alphaconcern-row gutter-30">
          <div className="col span_1_of_4">
            <div className="widget widget_text">
              <div className="textwidget">
                <p>
                  <img
                    src="/assets/img/logo-white@2x.png"
                    alt="imagealt"
                    width={170}
                    height={34}
                  />
                </p>
                <p className="margin-bottom-15">{profile?.footer_text}</p>
                <ul>
                  <li>
                    <div className="inner">
                      <span className="fa fa-map-marker" />
                      <span className="text">{profile.address}</span>
                    </div>
                  </li>
                  <li>
                    <div className="inner">
                      <span className="fa fa-phone" />
                      <span className="text">
                        CALL US : <a href={`tel:${profile.primary_phone}`} className="text-white">{profile.primary_phone}</a> 
                      </span>
                    </div>
                  </li>
                  <li className="margin-top-7">
                    <div className="inner">
                      <span className=" font-size-14 fa fa-envelope" />
                      <span className="text"><a href={`mail:${profile.primary_mail}`} className="text-white">{profile.primary_mail}</a></span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="spacer clearfix" />
          </div>
          <div className="col span_1_of_4">
            <div className="spacer clearfix" />
            <div className="widget widget_tags">
              <h2 className="widget-title margin-bottom-30">
                <span>TAGS</span>
              </h2>
              <div className="tags-list">
                {tags?.length > 0 &&  tags?.map((tag, ind) => (
                  <Link to="#" key={ind} className="text-capitalize">
                    {tag}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="col span_1_of_4">
            <div className="spacer clearfix" />
            <div className="widget widget_instagram">
              <h2 className="widget-title margin-bottom-30">
                <span>GALLERIES</span>
              </h2>
              <div className="instagram-wrap data-effect clearfix col3 g10">
                {galleries?.length > 0 && galleries?.map((gallery, gin) => (
                  <div
                    className="instagram_badge_image has-effect-icon"
                    key={gin}
                  >
                    <Link to="#" className="data-effect-item">
                      <span className="item">
                        <img
                          src={gallery?.file_path_url}
                          alt="imagealt"
                          style={{
                            height: 83,
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </span>
                      <div className="overlay-effect bg-color-2" />
                      <div className="elm-link">
                        <span className="icon-3" />
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
