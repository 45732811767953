import * as React from "react";

const Loader = () => (
  <div
    className="text-center overlay"
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
    }}
  >
    <div className="row d-block">
      <div className="col-12">
        <div className="loader"></div>
        <h6
          className="mt-3"
          style={{ color: "#ffc30c", fontSize: 24, fontWeight: 700 }}
        >
          Loading...
        </h6>
      </div>
    </div>
  </div>
);

export { Loader };
