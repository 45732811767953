import React from "react";
import Slider from "react-slick";
var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const Partner = ({ clientData }) => {
  return (
    <div className="row-partner">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="spacer clearfix" />
            <Slider {...settings}>
              {clientData?.length > 0 &&
                clientData?.map((partner, index) => (
                  <div
                    className="alphaconcern-partner style-1 align-center clearfix"
                    key={index}
                  >
                    <div className="partner-item">
                      <div className="inner border-0">
                        <div className="thumb">
                          <img
                            src={partner.file_path_url}
                            alt="partner image"
                            className="partner-default"
                          />
                          <img
                            src={partner.file_path_url}
                            alt="partner color image"
                            className="partner-color"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
            <div className="spacer clearfix" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partner;
