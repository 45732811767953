import React from "react";
import { Link } from "react-router-dom";

const Service = ({ serviceData }) => {
  return (
    <div
      className="alphaconcern-carousel-box data-effect clearfix"
      data-gap={30}
      data-column={3}
      data-column2={2}
      data-column3={1}
      data-auto="false"
    >
      <div className="row">
        {serviceData?.map((data, index) => (
          <div
            className="col-lg-4 col-sm-12 alphaconcern-image-box style-1 has-icon icon-right w65 clearfix"
            key={index}
          >
            <div className="image-box-item">
              <div className="inner">
                <div className="thumb data-effect-item">
                  <img src={data?.file_path_url} alt="altimage" />
                  <div className="overlay-effect bg-color-accent" />
                </div>
                <Link to={`/services-detail/${data.code}`}>
                  <div className="text-wrap">
                    <h5 className="heading">{data.title}</h5>
                    {/* <span className="icon-wrap w-25">
                      <i className="fa fa-angle-right" />
                    </span> */}
                    <button className="w-100 button-wrap"> Details </button>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Service;
