import React from 'react';

const Testimonial = ({testimonialData }) => {
        return (
            <div className="row-testimonials parallax-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="spacer clearfix" />
                            <div className="alphaconcern-carousel-box has-arrows arrow-center arrow-circle offset-v-24 clearfix"
                                data-gap={30} data-column={1} data-column2={1} data-column3={1} data-auto="true">
                                <div className="owl-carousel owl-theme">
                                    {testimonialData?.length > 0 && testimonialData?.map((data,ind) => (
                                            <div
                                            key={ind}
                                                className="alphaconcern-testimonials style-1 max-width-70 align-center has-width w100 circle border-solid clearfix"
                                                >
                                                <div className="testimonial-item">
                                                    <div className="inner">
                                                        <div className="icon-wrap">
                                                            <i className="fa fa-quote-left" />
                                                        </div>
                                                        <blockquote className="text">
                                                            <p>{data.comments}</p>
                                                            <div className="sep has-width w80 accent-bg clearfix" />
                                                            <h6 className="name">{data.full_name}</h6>
                                                        </blockquote>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="spacer clearfix" />
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default Testimonial;