import React from "react";
import BannerTitle from "../components/about/banner/BannerTitle";
import { Header, Footer, TopBar, BottomBar } from "../components/general/index";
import { useParams } from "react-router-dom";
import { useGetServiceDetail } from "../query-hook/home";
import parser from "react-html-parser";
import SEO from "../components/Seo";
import { Loader } from "../components/Loader";

const ServiceDetail = () => {
  const { slug } = useParams();
  const { data, isLoading } = useGetServiceDetail(slug);
  const serviceDetailData = data?.result;
  const headers = [
    {
      id: 1,
      logoweb: "assets/img/logo-small.png",
      names: "Services",
    },
  ];

  const banners = [
    {
      id: 1,
      links: "",
      titlelink: "",
      border: "",
      name: "Services",
      heading: "All Services",
    },
  ];

  return (
    <div className="header-fixed page sidebar-left header-style-2 topbar-style-1 menu-has-search">
      {isLoading && <Loader />}
      <SEO
        title={
          serviceDetailData?.meta_title ||
          "Service Details: Comprehensive Solutions Tailored to You"
        }
        description={
          serviceDetailData?.meta_description ||
          "Delve into the specifics of our construction services, designed to meet your unique needs. Explore the details, benefits, and applications of each service, exemplifying Alpha Concern's dedication to delivering customized solutions with excellence."
        }
        name="Alpha Concern Pvt. Ltd."
        type="website"
      />
      <div id="wrapper" className="animsition">
        <div id="page" className="clearfix">
          <div id="site-header-wrap">
            <TopBar />
            {headers.map((data, idx) => (
              <Header data={data} key={idx} />
            ))}
          </div>
          {banners.map((data) => (
            <BannerTitle key={data.id} data={data} />
          ))}
          <div id="main-content" className="site-main clearfix">
            <div id="content-wrap" className="container">
              <div className="spacer clearfix" />
              <div className="row">
                <div className="col-lg-4 col-sm-12">
                  <img src={serviceDetailData?.file_path_url} />
                </div>
                <div className="col-lg-8 col-sm-12 text-808 font-size-16 line-hieght-28">
                  <h1>{serviceDetailData?.title} </h1>
                  <p>{serviceDetailData?.short_description} </p>
                </div>
                <div className="col-12m mt-5">
                  <div className="flat-content-wrap style-3 clearfix">
                    <h5 className="title">SERVICE DESCRIPTION</h5>
                    <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
                    <div className="margin-top-28">
                      {serviceDetailData?.description &&
                        parser(serviceDetailData?.description)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="spacer clearfix" />
            </div>
          </div>
          <Footer />
          {headers.map((data, idx) => (
            <BottomBar data={data} key={idx} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceDetail;
