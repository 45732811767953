import React from "react";
import BannerTitle from "../components/about/banner/BannerTitle";
import { Header, Footer, TopBar, BottomBar } from "../components/general/index";
import { ContentProject, RelateProject, Sidebar } from "../components/project";
import { useGetProjectDetail } from "../query-hook/home";
import { useParams } from "react-router-dom";
import SEO from "../components/Seo";
import { Loader } from "../components/Loader";

const ProjectDetail = () => {
  const { slug } = useParams();
  const { data, isLoading } = useGetProjectDetail(slug);
  const projectDetailData = data?.result;
  const headers = [
    {
      id: 1,
      logoweb: "assets/img/logo-small.png",
      names: "Projects",
    },
  ];

  const banners = [
    {
      id: 1,
      links: "/project",
      titlelink: "Projects ",
      border: "|",
      name: "Project Single",
      heading: "Project Name",
    },
  ];

  return (
    <div className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
      {isLoading && <Loader />}
      <SEO
        title={
          projectDetailData?.meta_title ||
          "Project Details: Explore Our Construction Endeavors"
        }
        description={
          projectDetailData?.meta_description ||
          "Dive into the specifics of our construction projects, from concept to completion. Explore the details, challenges, and successes of each endeavor, showcasing Alpha Concern's commitment to excellence in every aspect of construction."
        }
        name="Alpha Concern Pvt. Ltd."
        type="website"
      />
      <div id="wrapper" className="animsition">
        <div id="page" className="clearfix">
          <div id="site-header-wrap">
            <TopBar />
            {headers.map((data, idx) => (
              <Header data={data} key={idx} />
            ))}
          </div>
          {banners.map((data) => (
            <BannerTitle key={data.id} data={data} />
          ))}
          <div id="main-content" className="site-main clearfix">
            <div id="content-wrap">
              <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                  <div className="page-content">
                    <div className="row-project-detail">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="spacer clearfix" />
                            <div className="detail-inner-wrap">
                              <ContentProject
                                data={projectDetailData}
                              />
                            </div>
                            <div className="spacer clearfix" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="alphaconcern-lines style-1 line-full line-solid clearfix">
                              <span className="line" />
                            </div>
                            <div className="spacer clearfix" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          {headers.map((data, idx) => (
            <BottomBar data={data} key={idx} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetail;
