import React from "react";
import { Link } from "react-router-dom";
import { useMainContext } from "../../context/MainContext";

const TopBar = () => {
  const { profile } = useMainContext();

  return (
    <div id="top-bar" style={{ background:"#00897b", color:"#FFF"}}>
      <div id="top-bar-inner" className="container">
        <div className="top-bar-inner-wrap">
          <div className="top-bar-content">
            <div className="inner">
              <span className="address content">{profile?.address}</span>
              <span className="phone content"><a href={`tel:${profile.primary_phone}`} className="text-white">{profile.primary_phone}</a></span>
              <span className="time content">{profile.office_time}</span>
            </div>
          </div>
          {profile.social_link?.length > 0 && (
            <div className="top-bar-socials">
              <div className="inner">
                <span className="text">Follow us:</span>
                {profile.social_link?.map((social, index) => (
                  <span className="icons" key={index}>
                    <Link to={social?.url ?? '#'}>
                      <i className={social?.icon} />
                    </Link>
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
