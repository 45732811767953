import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import parse from "react-html-parser";

const groupBy = (array, key) => {
  return array.reduce((result, item) => {
    // Check if the key exists in the result object
    if (!result[item[key]]) {
      // If not, create a new array for the key
      result[item[key]] = [];
    }
    // Push the item to the array corresponding to the key
    result[item[key]].push(item);
    return result;
  }, {});
};

const SiteContent = ({ aboutData, teamData }) => {
  const [teamArray, setTeamArray] = useState({});

  useEffect(() => {
    if (teamData?.length > 0) {
      const groupedData = groupBy(teamData, "tags");
      setTeamArray(groupedData);
    }
  }, [teamData]);

  function renderValue(label, value) {
    if (label?.toLowerCase() == "phone") {
      return <a href={`tel:${value}`}>{value}</a>;
    } else if (label?.toLowerCase() == "email") {
      return <a href={`mail:${value}`}>{value}</a>;
    } else {
      return value;
    }
  }

  return (
    <div className="clearfix">
      <div id="inner-content" className="inner-content-wrap">
        <div className="spacer clearfix" />
        <div className="alphaconcern-headings style-2 clearfix">
          <h2 className="heading text-uppercase">{aboutData?.title}</h2>
          <div className="sep has-width w80 accent-bg clearfix" />
          <div className="sub-heading font-size-16 line-height-28 text-777 margin-top-28 margin-right-12">
            {aboutData?.description && parse(aboutData?.description)}
          </div>
        </div>
        <div className="spacer clearfix" />
        <div className="alphaconcern-headings style-2 clearfix">
          <h2 className="heading">OUR TEAM</h2>
          <div className="sep has-width w80 accent-bg clearfix" />
          <p className="sub-heading font-size-16 line-height-28 text-666 margin-top-27">
            When you work with us, you can relax knowing that your dream house
            project is in the capable hands of a certified and insured building
            business.
          </p>
        </div>
        <div
          className="alphaconcern-content-box clearfix"
          data-margin="0 15px 0 0"
          data-mobilemargin="0 0 0 0"
        >
          {Object.keys(teamArray)?.length > 0 &&
            Object.entries(teamArray).map(([group, items]) => (
              <div className="row no-gutters comment-list style-2" key={group}>
                <div className="col-12">
                  <div className="spacer clearfix" />
                  <div className="alphaconcern-headings style-2 text-center">
                    <h3 className="heading text-uppercase">{group}</h3>
                    <div className="sep has-width w80 w-100 accent-bg clearfix" />
                  </div>
                  <div className="spacer clearfix" />
                </div>
                {items?.map((data, index) => (
                  <div className="col-lg-6 col-sm-12 comment" key={index}>
                    <article className="comment-wrap clearfix p-0 mb-5">
                      <div className="gravatar">
                        <img
                          alt="imagealt"
                          src={
                            data.file_path_url ||
                            "/assets/img/team/member-1-370x359.jpg"
                          }
                        />
                      </div>
                      <div className="comment-content m-0">
                        <div className="comment-meta">
                          <h6 className="comment-author">{data.full_name}</h6>
                        </div>
                        <span className="comment-position text-808">
                          {data.designation}
                        </span>
                        <div className="comment-text mt-2">
                          {/* <p>{data.description}</p> */}
                          <div className="socials">
                            <ul className="m-0">
                              {data?.information?.length > 0 &&
                                data?.information?.map(
                                  (s) =>
                                    s.value && (
                                      <li
                                        key={s.label}
                                        style={{ background: "unset" }}
                                      >
                                        <b>{s.label}</b> :{" "}
                                        {renderValue(s.label, s.value)}
                                      </li>
                                    )
                                )}
                              {data?.social_profile?.length > 0 && (
                                <li style={{ background: "unset" }}>
                                  <b>Social Profile</b> :&nbsp;
                                  <span>
                                    {data?.social_profile?.map((s) =>
                                      s?.value ? (
                                        <a
                                          href={s?.value || "#"}
                                          target="_blank"
                                          className={s?.label}
                                          key={s.label}
                                          style={{ marginRight: 12 }}
                                        >
                                          <span
                                            className={`fa fa-${s?.label}`}
                                          />
                                        </a>
                                      ) : (
                                        "N/A"
                                      )
                                    )}
                                  </span>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                ))}
              </div>
            ))}
        </div>
        <div className="spacer clearfix" />
      </div>
    </div>
  );
};

export default SiteContent;
