import React from "react";
import { Link } from "react-router-dom";

const ServicesBox = ({ serviceCategoryData }) => {
  const icon = [
    "autora-icon-build",
    "autora-icon-hat-outline",
    "autora-icon-author-outline",
    "autora-icon-build-home",
    "autora-icon-building-outline",
    "autora-icon-hat-outline",
  ];

  return (
    <div className="clearfix px-3">
      {serviceCategoryData?.length > 0 && (
        <div className="row">
          {serviceCategoryData.map((spandata, sid) => (
            <div
              className="col-lg-4 col-md-4 col-sm-12 col-xs-12 alphaconcern-icon-box icon-left accent-color clearfix"
              key={sid}
            >
              <div className="icon-wrap">
                <i className={icon[sid]} />
              </div>
              <div className="text-wrap">
                <h5 className="heading">
                  <Link to="#">{spandata.title}</Link>
                </h5>
                <p className="sub-heading">{spandata.description}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ServicesBox;
