import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMainContext } from "../../context/MainContext";

const About = () => {
  const { profile } = useMainContext();
  return (
    <div className="row-about">
      <div className="container-fluid">
        <div className="row equalize sm-equalize-auto">
          <div className="col-md-6 half-background style-1" />
          <div className="col-md-6 bg-light-grey">
            <div className="spacer clearfix" />
            <div
              className="alphaconcern-content-box clearfix"
              data-margin="0 25% 0 4.5%"
              data-mobilemargin="0 0 0 4.5%"
            >
              <div className="alphaconcern-headings style-1 clearfix">
                <h2 className="heading">WELCOME TO ALPHA</h2>
                <div className="sep has-width w80 accent-bg margin-top-11 clearfix" />
                <div className="sub-heading margin-top-30">
                  {profile?.introduction}
                </div>
              </div>
              <div className="spacer clearfix" />
              <div className="elm-button">
                <Link to="/contact" className="alphaconcern-button bg-white">
                  GET IN TOUCH
                </Link>
              </div>
            </div>
            <div className="spacer clearfix" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
