import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

var settings = {
  dots: false,
  infinite: false,
  arrows: false,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const Project = ({ projectData }) => {
  return (
    <div>
    <div
      className="clearfix"
      data-gap={30}
      data-column={4}
      data-column2={2}
      data-column3={1}
      data-auto="false"
    />
      <Slider {...settings}>
        {projectData?.length > 0 &&
          projectData?.map((project, index) => (
            <div
              className="alphaconcern-project style-1 data-effect clearfix p-3"
              key={index}
            >
              <div className="project-item">
                <div className="inner">
                  <div className="thumb data-effect-item has-effect-icon w40 offset-v-43 offset-h-46">
                    <img src={project?.file_path_url} alt="altimage" />
                    <div className="text-wrap text-center">
                      <h5 className="heading">
                        <Link to="#">{project.title}</Link>
                      </h5>
                      <div className="elm-meta">
                        <span>
                          <Link to="#">{project?.category_title}</Link>
                        </span>
                        {/* <span>
                          <Link to="#">{project?.text}</Link>
                        </span> */}
                      </div>
                    </div>
                    {/* <div className="elm-link">
                      <Link to="#" className="icon-1 icon-search"></Link>
                      <Link to="#" className="icon-1"></Link>
                    </div> */}
                    <div className="overlay-effect bg-color-3" />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default Project;
