import React from "react";
import { BrowserRouter, Route } from 'react-router-dom'
import routes from './pages/index'
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from 'react-toastify';
import { MainProvider } from "./context/MainContext";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const queryClient = new QueryClient();

function App() {
  return (
    <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <MainProvider>
        <BrowserRouter>
          {
            routes.map((data, idx) => (
              <Route exact path={data.path} component={data.component} key={idx}></Route>
            ))
          }
        </BrowserRouter>
        <ToastContainer />
      </MainProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;
