import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Sliders = ({ bannerData }) => {
  return (
    <Slider {...settings}>
    {bannerData?.length > 0 &&
      bannerData?.map((data, index) => (
        <div className="banner-slide" key={`slide-${index}`}>
          <img
            src={data?.file_path_url}
            alt="altimage"
            data-bgposition="center center"
            data-no-retina
            className="banner-image"
          />
          <div className="banner-content">
            <h4 className="text-white font-heading font-weight-600">
              {data?.title}
            </h4>
            <h1 className="text-white font-heading font-weight-700 text-capitalize">
              {data?.description}
            </h1>
            <div className="d-flex g-2">
              {data?.buttons?.length > 0 &&
                data?.buttons?.map((button, i) => (
                  <Link
                    to={button?.url ?? "#"}
                    className="alphaconcern-button bg-accent big"
                    key={`button-${index}-${i}`}
                  >
                    <span>{button.name}</span>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      ))}
  </Slider>
  );
};

export default Sliders;
