import React from "react";
const Sidebar = ({ data }) => {
  return (
    <div className="w-100">
      <div className="content-info">
        <div className="alphaconcern-headings style-2 clearfix">
          <h2 className="heading line-height-62">PROJECT NAME</h2>
          <div className="sep has-width w80 accent-bg clearfix"></div>
        </div>
        <ul className="list-info has-icon icon-left">
          <li>
            <span className="text">
              Client{" "}
              <span className="icon">
                <i className="fa fa-user" />
              </span>
            </span>
            <span className="right">{data?.client}</span>
          </li>
          <li>
            <span className="text">
              Budget{" "}
              <span className="icon">
                <i className="fa fa-usd" />
              </span>
            </span>
            <span className="right">{data?.budget}</span>
          </li>
          <li>
            <span className="text">
              Surface Area{" "}
              <span className="icon">
                <i className="fa fa-search" />
              </span>
            </span>
            <span className="right">{data?.area} </span>
          </li>
          <li>
            <span className="text">
              Year completed{" "}
              <span className="icon">
                <i className="fa fa-calendar" />
              </span>
            </span>
            <span className="right">{data?.year}</span>
          </li>
          <li>
            <span className="text">
              Location:{" "}
              <span className="icon">
                <i className="fa fa-folder-open" />
              </span>
            </span>
            <span className="right">{data?.address}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
