import React from "react";
import { Link } from "react-router-dom";

const TabProject = ({ projectData }) => {
  return (
    <div className="row">
      {projectData?.length > 0 &&
        projectData.map((data, index) => {
          const url = "/project-detail/" + data.code;
          return (
            <div
              className="col-lg-6 col-md-4 col-sm-12 project-item mb-5"
              key={index}
            >
              <div className="inner">
                <div className="w40 offset-v-19 offset-h-49 zoom-img">
                  <img src={data.file_path_url} alt="altimage" />
                  <div className="overlay-effect bg-color-3" />
                </div>
                <Link to={url}>
                  <div className="text-wrap">
                    <h5 className="heading mt-2 mb-2">{data.title}</h5>
                    <div className="elm-meta">
                      <span>{data.category_title}</span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default TabProject;
