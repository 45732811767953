import React, { useState } from "react";
import BannerTitle from "../components/about/banner/BannerTitle";
import { FormContact, IconBox } from "../components/contact";
import { TitleHeading } from "../components/home/index";
import { Header, Footer, TopBar, BottomBar } from "../components/general/index";
import SEO from "../components/Seo";

const Contact = () => {
  const headers = [
    {
      id: 1,
      logoweb: "assets/img/logo-small.png",
      names: "Contact",
    },
  ];

  const banners = [
    {
      id: 1,
      links: "",
      titlelink: "",
      border: "",
      name: "Contact",
      heading: "Contact Us",
    },
  ];

  const headingcontact = [
    {
      id: 1,
      classnames: "heading",
      title: "CONTACT US",
      text: "Would you like to know how Alpha Concern Construction Services can help your project turn out well? Please get in touch with us for more details about our services.",
      classtext:
        "sub-heading font-weight-400 max-width-770 line-height-26 margin-top-14",
    },
  ];

  return (
    <div className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
      <SEO
        title="Contact Us: Reach Out for Construction Solutions"
        description="Connect with Alpha Concern through our contact page to discuss your construction needs. Whether you have inquiries, project ideas, or collaboration opportunities, our team is ready to assist you. Let's build together."
        name="Alpha Concern Pvt. Ltd."
        type="website"
      />
      <div id="wrapper" className="animsition">
        <div id="page" className="clearfix">
          <div id="site-header-wrap">
            <TopBar />
            {headers.map((data, idx) => (
              <Header data={data} key={idx} />
            ))}
          </div>
          {banners.map((data) => (
            <BannerTitle key={data.id} data={data} />
          ))}
          <div id="main-content" className="site-main clearfix">
            <div id="content-wrap">
              <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                  <div className="page-content">
                    <div className="row-iconbox">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="spacer clearfix" />
                            {headingcontact.map((data) => (
                              <TitleHeading data={data} key={data.id} />
                            ))}
                            <div className="spacer clearfix" />
                          </div>
                        </div>
                        <IconBox />
                        <div className="row">
                          <div className="col-md-12">
                            <div className="spacer clearfix" />
                          </div>
                        </div>
                        <FormContact />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        {headers.map((data, idx) => (
          <BottomBar data={data} key={idx} />
        ))}
      </div>
    </div>
  );
};

export default Contact;
