
const REACT_APP_API_BASE_URL = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/api/v1' : 'https://api.alphaconcern.com/api/v1';

export const queryConfig = {
	refetchOnWindowFocus: false,
	keepPreviousData: true,
};

export const apiEndpoint = {
	base: REACT_APP_API_BASE_URL,
	home: `${REACT_APP_API_BASE_URL}/home`,
	about: `${REACT_APP_API_BASE_URL}/about`,
	service: `${REACT_APP_API_BASE_URL}/service`,
	project: `${REACT_APP_API_BASE_URL}/project`,
	page: `${REACT_APP_API_BASE_URL}/page`,
	team: `${REACT_APP_API_BASE_URL}/team`,
	client: `${REACT_APP_API_BASE_URL}/client`,
	gallery: `${REACT_APP_API_BASE_URL}/gallery`,
	contactUs: `${REACT_APP_API_BASE_URL}/enquiry`,
};


