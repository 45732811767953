import React from "react";
import BannerTitle from "../components/about/banner/BannerTitle";
import { SiteContent } from "../components/about/detail";
import { Header, Footer, TopBar, BottomBar } from "../components/general/index";
import { useGetPage, useGetTeam } from "../query-hook/home";
import SEO from "../components/Seo";
import { Loader } from "../components/Loader";

const About = () => {
  const { data: aboutData, isLoading } = useGetPage("about-us");
  const { data: teamData } = useGetTeam();
  const headers = [
    {
      id: 1,
      logoweb: "assets/img/logo-small.png",
      names: "About Us",
    },
  ];

  const banners = [
    {
      id: 1,
      links: "/about-us",
      titlelink: "About Us",
      border: "|",
      name: "Detail",
      heading: "Who we are?",
    },
  ];

  return (
    <div className="header-fixed page sidebar-right width-71 header-style-2 topbar-style-1 menu-has-search">
      {isLoading && <Loader />}
      <SEO
        title={
          aboutData?.result?.meta_title ||
          "Learn About Us: Our Story, Values, and Commitment "
        }
        description={
          aboutData?.result?.meta_description ||
          "Discover the essence of Alpha Concern - our journey, values, and dedication to excellence. Explore our about page to understand our mission and how we strive to deliver exceptional construction solutions."
        }
        name="Alpha Concern Pvt. Ltd."
        type="website"
      />
      <div id="wrapper" className="animsition">
        <div id="page" className="clearfix">
          <div id="site-header-wrap">
            <TopBar />
            {headers.map((data, idx) => (
              <Header data={data} key={idx} />
            ))}
          </div>
          {banners.map((data) => (
            <BannerTitle key={data.id} data={data} />
          ))}
          <div id="main-content" className="site-main clearfix">
            <div id="content-wrap" className="container">
              <SiteContent
                aboutData={aboutData?.result}
                teamData={teamData?.result}
              />
              {/* <SideBar /> */}
            </div>
          </div>
        </div>
        <Footer />
        {headers.map((data, idx) => (
          <BottomBar data={data} key={idx} />
        ))}
      </div>
    </div>
  );
};

export default About;
