import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { apiEndpoint, queryConfig } from "../config";
import { toast } from 'react-toastify';

// QUERY KEYS
export const bannerQueryKey = "bannerImages";
export const productsQueryKey = "allProducts";
export const aboutQueryKey = "aboutUs";
export const partnerQueryKey = "partner";
export const faqQueryKey = "faq";
export const contactUsQueryKey = "contactUs";
export const projectQueryKey = "project";
export const serviceQueryKey = "service";
export const pageQueryKey = "page";
export const teamQueryKey = "team";
export const galleryQueryKey = "gallery";
export const clientQueryKey = "client";
export const serviceDetailQueryKey = "serviceDetail";
export const projectDetailQueryKey = "projectDetail";

export const useQueryClientCustom = (
	params,
	cacheKey,
	endpoint,
	config = {},
) => {
	return useQuery(
		[cacheKey, params],
		() =>
			axios.get(endpoint, params)
				.then((res) => res.data)
				.catch((err) => null),
		{ ...queryConfig, ...config }
	);
};

export const useGetHome = (
	params = null,
	config = {}
) => {
	return useQueryClientCustom(params, bannerQueryKey, apiEndpoint.home, config);
};

export const useGetAbout = (
	params = null,
	config = {}
) => {
	return useQueryClientCustom(params, aboutQueryKey, apiEndpoint.about, config);
};

export const useGetPage = (
	slug = 'home | product | contact| about',
	config = {}
) => {
	return useQueryClientCustom({ slug }, pageQueryKey, `${apiEndpoint.page}/${slug}`, config);
};

export const useGetTeam = (
	params = null,
	config = {}
) => {
	return useQueryClientCustom(params, teamQueryKey, apiEndpoint.team, config);
};

export const useGetClient = (
	params = null,
	config = {}
) => {
	return useQueryClientCustom(params, clientQueryKey, apiEndpoint.client, config);
};

export const useGetService = (
	params = null,
	config = {}
) => {
	return useQueryClientCustom(params, serviceQueryKey, apiEndpoint.service, config);
};

export const useGetServiceDetail = (
	slug,
	config = {}
) => {
	return useQueryClientCustom({ slug }, serviceDetailQueryKey, `${apiEndpoint.service}/${slug}`, config);
};

export const useGetProject = (
	params = null,
	config = {}
) => {
	return useQueryClientCustom(params, projectQueryKey, apiEndpoint.project, config);
};

export const useGetProjectDetail = (
	slug,
	config = {}
) => {
	return useQueryClientCustom({ slug }, projectDetailQueryKey, `${apiEndpoint.project}/${slug}`, config);
};

export const useContactUs = () => {
	const queryClientUse = useQueryClient();
	return useMutation(
		(body) =>
			axios.post(apiEndpoint.contactUs, body)
				.then((res) => res),
		{
			onSuccess: (data) => {
				toast("Successfull.");
			},
			onError: (err) => {
				if (typeof err?.data?.message != "object") {
					toast(err?.data?.message ?? 'Something wrong!');
				}
			},
			onSettled: () => {
				queryClientUse.refetchQueries(contactUsQueryKey);
			},
		}
	);
};

export const useGetGallery = (
	params = null,
	config = {}
) => {
	return useQueryClientCustom(params, galleryQueryKey, apiEndpoint.gallery, config);
};
