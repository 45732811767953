import React from "react";
import { Link } from "react-router-dom";

const ServiceSlide = ({ serviceData }) => {
  return (
    <div className="row-services">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="spacer clearfix" />
            <div className="row">
              {serviceData?.length > 0 &&
                serviceData.map((service, i) => (
                  <div
                    className="col-lg-4 col-md-4 col-sm-12 col-xs-12 clearfix"
                    key={i}
                  >
                    <div className="image-box-item">
                      <div className="inner">
                        <div className="thumb data-effect-item zoom-img">
                          <img src={service.file_path_url} alt="altimage" />
                          {/* <div className="overlay-effect bg-color-accent" /> */}
                        </div>
                        <div className="text-wrap py-3 clearfix">
                          <h5 className="heading">
                            <Link to={`/services-detail/${service.code}`}>
                              {service.title}
                            </Link>
                          </h5>
                          <p className="letter-spacing-01">
                            {service.short_description}
                          </p>
                          <Link
                            to={`/services-detail/${service.code}`}
                            className="text-white"
                          >
                            <button className="w-100">DETAILS</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="spacer clearfix" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSlide;
