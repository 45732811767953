import React from "react";
import { Link, useLocation } from "react-router-dom";
import menus from "../menu";

const Header = ({ data }) => {
  const { pathname } = useLocation();
  return (
    <header id="site-header">
      <div id="site-header-inner" className="container">
        <div className="wrap-inner clearfix">
          <div id="site-logo" className="clearfix">
            <div id="site-log-inner">
              <Link
                to="/"
                rel="home"
                className="main-logo"
              >
                <img
                  src="/assets/img/logo-small@2x.png"
                  alt="Alpha Concern"
                  style={{
                    height: 80,
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
          </div>
          <div className="mobile-button">
            <span />
          </div>
          <nav id="main-nav" className="main-nav">
            <ul id="menu-primary-menu" className="menu">
              {menus.map((menu) => (
                <li
                  className={
                    menu.name === data.names
                      ? "menu-item menu-item-has-children current-menu-item"
                      : "menu-item menu-item-has-children"
                  }
                  key={menu.id}
                >
                  <Link
                    to={menu?.links ?? "#"}
                  >
                    {menu.name}
                  </Link>
                  {menu?.namesub?.length > 0 && (
                    <ul className="sub-menu">
                      {menu.namesub.map((submenu) => (
                        <li
                          className={
                            pathname === submenu.links
                              ? "menu-item current-item"
                              : "menu-item"
                          }
                          key={submenu.id}
                        >
                          <Link
                            to={submenu.links}
                          >
                            {submenu.sub}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          {/* <div id="header-search">
              <Link to="#" className="header-search-icon">
                <span className="search-icon fa fa-search"></span>
              </Link>
              <form
                role="search"
                method="get"
                className="header-search-form"
                action="#"
              >
                <label className="screen-reader-text">Search for:</label>
                <input
                  type="text"
                  defaultValue
                  name="s"
                  className="header-search-field"
                  placeholder="Search..."
                />
                <button
                  type="submit"
                  className="header-search-submit"
                  title="Search"
                >
                  <i className="fa fa-search" />
                </button>
              </form>
            </div> */}
        </div>
      </div>
    </header>
  );
};

export default Header;
