import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useContactUs } from "../../query-hook/home";

function errorMessage(error) {
  switch (error.type) {
    case "required":
      return error.message || "Required";
    default:
      return error.message;
  }
}

const FormErrorMessage = React.memo(({ error, className = "", style = {} }) => {
  return error ? (
    <span className={`text-danger d-flex mt-2 g-2 ${className}`} style={style}>
      <p className="mb-0">{errorMessage(error)} *</p>
    </span>
  ) : null;
});

const FormContact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    mode: "all",
  });

  const { mutateAsync, error, isSuccess, reset: contactReset } = useContactUs();

  const onSubmit = (data) => {
    mutateAsync(data);
  };

  useEffect(() => {
    if (error?.data?.errors) {
      Object.keys(error?.data?.errors).map((err) =>
        setError(err, {
          type: "manual",
          message: error?.data?.errors[err][0],
        })
      );
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      reset();
      contactReset();
    }
  }, [isSuccess]);

  return (
    <div className="row-contact">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="alphaconcern-contact-form style-2 w100 clearfix">
              <form
                action="#"
                method="post"
                className="contact-form wpcf7-form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <span className="wpcf7-form-control-wrap your-name">
                  <input
                    type="text"
                    tabIndex={1}
                    id="full_name"
                    {...register("full_name", {
                      required: true,
                    })}
                    defaultValue={""}
                    className="wpcf7-form-control"
                    placeholder="Name*"
                    required
                  />
                  <FormErrorMessage error={errors?.full_name} />
                </span>
                <span className="wpcf7-form-control-wrap your-email">
                  <input
                    type="email"
                    tabIndex={3}
                    id="email"
                    {...register("email", {
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email",
                      },
                    })}
                    defaultValue={""}
                    className="wpcf7-form-control"
                    placeholder="Your Email*"
                    required
                  />
                  <FormErrorMessage error={errors?.email} />
                </span>
                <span className="wpcf7-form-control-wrap your-phone">
                  <input
                    type="text"
                    tabIndex={2}
                    id="phone"
                    {...register("phone", {
                      required: true,
                    })}
                    defaultValue={""}
                    className="wpcf7-form-control"
                    placeholder="Phone"
                  />
                  <FormErrorMessage error={errors?.phone} />
                </span>
                <span className="wpcf7-form-control-wrap your-message">
                  <textarea
                    name="message"
                    tabIndex={5}
                    cols={40}
                    rows={10}
                    {...register("message", {
                      required: true,
                    })}
                    className="wpcf7-form-control wpcf7-textarea"
                    placeholder="Message*"
                    required
                    defaultValue={""}
                  />
                  <FormErrorMessage error={errors?.message} />
                </span>
                <span className="wrap-submit">
                  <input
                    type="submit"
                    defaultValue="SEND US"
                    className="submit wpcf7-form-control wpcf7-submit"
                    id="submit"
                    name="submit"
                  />
                </span>
              </form>
            </div>
          </div>
          <div className="col-md-12">
            <div className="spacer clearfix" />
            {/* <div className="alphaconcern-map style-2" /> */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="spacer clearfix" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormContact;
